$viewport_medium : 768px !default;
$viewport_large : 1025px !default;

@mixin viewport-large {
  @media (min-width: #{$viewport_large}) {
    @content;
  }
}

@mixin viewport-medium {
  @media (min-width: #{$viewport_medium}) {
    @content;
  }
}

@mixin viewport-medium-only {
  @media (min-width: #{$viewport_medium}) and (max-width: #{$viewport_large - 1px}) {
    @content;
  }
}

@mixin viewport-small-only {
  @media (min-width: 0) and (max-width: #{$viewport_medium - 1px}) {
    @content;
  }
}