@import '../../utils/system-unit.scss';
@import '../../utils/viewport.scss';

.projectBlockContainer {
    margin: system-unit(0.5) system-unit(0.5) 0 0;

    button {
        right: system-unit(0.5);
    }

    @include viewport-small-only {
        margin-right: 0;
    }

    .projectsBlock {
        background-color: transparent;
        padding: 0 system-unit(1) system-unit(1.25) 0;
        max-height: 0;

        @include viewport-small-only {
            padding: 0 system-unit(1) system-unit(9) system-unit(1);
        }

        &.projectsBlockShow {
            background-color: #000 !important;
            max-height: none;
            overflow-y: scroll;
            overflow-x: hidden;
            height: 99vh;
        }

        .projectBlock {
            z-index: 1000;

            &:last-child {
                margin-bottom: system-unit(2.25);
            }

            .projectImageWrapper {
                position: relative;
                max-width: 400px;
                padding: system-unit(0.5) 0px;
                opacity: 1;

                // background-color: black;
                @include viewport-small-only {
                    max-width: 100%;
                }

                img {
                    max-width: 400px;
                    transition: opacity 0.25s ease;

                    @include viewport-small-only {
                        max-width: 100%;
                    }
                }

                div {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    color: #fff;
                    opacity: 0;
                    display: flex;
                    align-items: center;
                    text-align: center;
                    text-transform: uppercase;
                    transition: opacity 0.25s ease;
                    width: 400px;
                    height: 100%;

                    @include viewport-small-only {
                        width: 100%;
                    }

                    p {
                        margin: 0 auto;
                        font-size: system-unit(2);
                        font-family: 'Proxima Nova Light';
                    }
                }

                &:hover {
                    cursor: pointer;

                    img {
                        opacity: 0.25;
                    }

                    div {
                        opacity: 1;

                    }
                }
            }
        }
    }
}

.projectFullWrapper {
    position: fixed;
    display: flex;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 9000;
    background-color: black;
    color: white;
    transition: opacity 0.25s ease-out;
    overflow-y: scroll;
    flex-direction: column;
    opacity: 0;

    &.projectFullWrapperShow {
        opacity: 1;
    }

    .projectFullContainer {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include viewport-small-only {
            padding: 0 system-unit(1);
        }
    }

    .projectFullClose {
        align-self: flex-end;
        padding: system-unit(2);
        cursor: pointer;
        position: fixed;
        font-size: system-unit(2);
        z-index: 2000;

        @include viewport-small-only {
            padding: system-unit(1);
        }

        &:hover {
            opacity: 0.75;
        }
    }

    .projectInfoContainer {
        text-align: center;
        width: 100%;
        padding: system-unit(2) 0 0 0;
        position: fixed;
        height: 400px;

        @include viewport-small-only {
            width: 100%;
            position: relative;
            height: auto;
            text-align: left;
        }

        h1 {
            text-transform: uppercase;
            font-size: 80px;
            letter-spacing: 1px;
            transition: opacity 0.5s;
            margin-bottom: 24px;

            @include viewport-small-only {
                font-size: system-unit(3.5);
                margin-top: system-unit(3);
                margin-bottom: system-unit(1);
            }
        }

        .projectDescriptionContainer {
            display: flex;
            justify-content: space-evenly;

            @include viewport-small-only {
                flex-direction: column;
                margin-bottom: system-unit(0.5);
            }

            .role {
                width: 45%;
            }

            div {
                display: flex;
                flex-direction: column;
                text-align: center;
                align-items: center;

                @include viewport-small-only {
                    text-align: left;
                }

                h6 {
                    @include viewport-small-only {
                        margin-bottom: 0;
                    }
                }

                &:nth-child(odd) {
                    width: 25%;

                    @include viewport-small-only {
                        width: 100%;
                        margin-bottom: system-unit(0.5);
                    }
                }

                &:nth-child(even) {
                    width: 50%;

                    @include viewport-small-only {
                        width: 100%;
                        margin-bottom: system-unit(0.5);
                    }
                }
            }

            span {
                padding-top: system-unit(0.5);
            }

            .projectInfoLinks {
                padding-top: 0;

                a {
                    &:first-child {
                        color: #ff00ee;
                    }

                    color: #eeff00;
                    font-style: italic;

                    &:hover {
                        color: white;
                    }
                }
            }
        }
    }

    .projectAssetsWrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .projectAssetsBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 60%;
        margin-top: 350px;
        margin-bottom: system-unit(0.5);
        z-index: 1000;
        background: #000;
        padding-bottom: system-unit(1.5);

        @include viewport-small-only {
            width: 100%;
            margin-top: 0;
        }

        &:first-child {
            padding-top: 0;
        }

        span {
            text-align: center;
            width: 100%;
        }

        p {
            font-size: system-unit(0.875);
        }

        img,
        video {
            padding: system-unit(1) 0px;
            width: 85%;
            margin: 0;
            max-width: 100%;
            cursor: default;

            &:hover {
                opacity: 1;
            }

            @include viewport-small-only {
                width: 100%;
                padding: system-unit(0.5) 0px;
            }
        }
    }
}