.squareWaveWrapper {
	height: 100vh;
	width: 100vw;
	position: fixed;
	overflow: hidden;
	z-index: -1000;
	canvas {
		height: 100vh;
		width: 100vw;
		position: fixed;
		overflow: hidden;
		z-index: -1000;
	}

}