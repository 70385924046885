@import './utils/typography.scss';
@import './utils/glitch.scss';
@import './utils/system-unit.scss';
@import './utils/viewport.scss';

:root {
  font-size: 100%;
}

html {
  box-sizing: border-box;
  *:focus-visible {
    outline: none;
  }
}

html,
body {
  overflow-x: hidden;
  overflow-y: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Proxima Nova Regular', sans-serif;
  position: relative;
  #content {
    display: flex;
    justify-content: space-between;
    z-index: 1;
  }
  #nav {
    display: flex;
    justify-content: space-between;
    margin: 0 system-unit(0.5);
    padding: system-unit(0.5) 0;
  }
}

a {
  text-decoration: none;

  &:hover,
  &:active,
  &:visited {
    color: white;
  }
}

video,
button {
  &:focus {
    outline: none;
  }
}

button {
  display: inline-block;
  padding: 0 system-unit(1);
  color: #fff;
  font-family: 'Proxima Nova SemiBold', sans-serif;
  text-align: center;
  font-size: system-unit(1.5);
  line-height: system-unit(2.5);
  letter-spacing: system-unit(0.25);
  text-transform: uppercase;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  box-sizing: border-box;
  z-index: 0;
  &:hover {
    outline: 1px solid #bbb;
  }
  &:focus {
    &:hover {
      outline: 1px solid #bbb;
    }
  }
  @include viewport-small-only {
    padding: 0 system-unit(0.5);
    &:hover {
      outline: none;
    }
    &:focus {
      &:hover {
        outline: none;
      }
    }
  }
}

.waterfall {
  position: relative;
  right: -400px;
  opacity: 0;
  transition: all 0.75s ease;
}
.waterfall-in {
  right: 0px;
  opacity: 1;
}

.fade-in-section {
  opacity: 0;
  transform: translateY(50vh);
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  will-change: opacity, visibility;
}
.fade-in-section.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-leave {
  opacity: 1;
}

.fade-leave.fade-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.fade-out {
  opacity: 0;
  @include viewport-small-only {
    opacity: 1;
  }
}
