@import './system-unit.scss';

@mixin font-face($woff:false, $woff2:false, $ttf:false, $otf:false) {
	@font-face {

		$list: ();

		@if($woff !=false) {
			$woffSrc: url($woff) format('woff');
			$list: append($list, $woffSrc, comma);
		}

		@if($woff2 !=false) {
			$woff2Src: url($woff2) format('woff2');
			$list: append($list, $woff2Src, comma);
		}

		@if($ttf !=false) {
			$ttfSrc: url($ttf) format('truetype');
			$list: append($list, $ttfSrc, comma);
		}

		@if($otf !=false) {
			$otfSrc: url($otf) format('opentype');
			$list: append($list, $otfSrc, comma);
		}

		src : $list;

		@content;
	}
}

@include font-face($otf: "../assets/fonts/proxima-nova/ProximaNova-Light.otf") {
	font-weight: 300;
	font-family: 'Proxima Nova Light';
}

@include font-face($otf: "../assets/fonts/proxima-nova/ProximaNova-Regular.otf") {
	font-weight: 400;
	font-family: 'Proxima Nova Regular';
}

@include font-face($otf: "../assets/fonts/proxima-nova/ProximaNova-Semibold.otf") {
	font-weight: 600;
	font-family: 'Proxima Nova SemiBold';
}

@include font-face($otf: "../assets/fonts/benton-sans/BentonSansF-Book.otf") {
	font-weight: 300;
	font-family: 'Benton Sans Book';
}

@include font-face($otf: "../assets/fonts/benton-sans/BentonSansF-Medium.otf") {
	font-weight: 500;
	font-family: 'Benton Sans Medium';
}

h1 {
	font-family: 'Proxima Nova SemiBold', sans-serif;
	font-size: system-unit(3.75);
	margin: system-unit(2) 0;
}

h2 {
	font-family: 'Proxima Nova SemiBold', sans-serif;
	font-size: system-unit(3);
	margin: system-unit(1.5) 0;
}

h3 {
	font-family: 'Proxima Nova SemiBold', sans-serif;
	font-size: system-unit(2.5);
	margin: system-unit(1.25) 0;
}

h4 {
	font-family: 'Proxima Nova SemiBold', sans-serif;
	font-size: system-unit(2);
	margin: system-unit(1) 0;
}

h5 {
	font-family: 'Proxima Nova SemiBold', sans-serif;
	font-size: system-unit(1.5);
	margin: system-unit(0.75) 0;
}

h6 {
	font-family: 'Proxima Nova SemiBold', sans-serif;
	font-size: system-unit(1.25);
	margin: system-unit(0.66) 0;
}

p {
	font-family: 'Benton Sans Book', sans-serif;
	font-size: system-unit(1);
	margin: system-unit(0.5) 0;
}

button {
	font-family: 'Benton Sans Book', sans-serif;
}