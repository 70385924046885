@import '../../utils/system-unit.scss';
@import '../../utils/viewport.scss';

.aboutBlockContainer {
    margin: system-unit(0.5) 0 0 system-unit(0.5);
    @include viewport-small-only {
        position: absolute;
        height: 100vh;
        width: 100vw;
    }
    .aboutBlock {
        background-color: transparent;
        padding: 0 0 0 system-unit(1);
        color: white;
        max-height: 0;
        opacity: 0;
        transition: opacity 0.5s ease-out;
        width: 400px;
        a{
            color: #fff;
        }
        @include viewport-small-only() {
            width: auto;
            padding: 0 0 0 system-unit(0.5);
        }
        &.aboutBlockShow {
            opacity: 1;
            @include viewport-small-only() {
                max-height: 100%;
                height: 100%;
                margin-right: system-unit(1);
                background-color: black
            }
        }
        div {
            margin-bottom: system-unit(1);
        }
        .aboutLinks {
            a {
                padding: 0 system-unit(0.25);
                font-style: italic;
                color: #fff;
                &:hover {
                    font-style: normal;
                }
            }
        }
    }
}